import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Container, Divider, Typography } from '@mui/material';
import { CssBaseline } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import * as THREE from 'three';
import FOG from 'vanta/dist/vanta.fog.min';
import Typed from 'typed.js';

export default function LandingPage() {
    const navigate = useNavigate();
    const vantaRef = useRef<HTMLDivElement>(null);
    const vantaEffect = useRef<any>(null);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        // Initialize Vanta
        if (THREE && FOG) {
            try {
                if (vantaRef.current) {
                    vantaEffect.current = FOG({
                        el: vantaRef.current,
                        mouseControls: true,
                        touchControls: true,
                        gyroControls: false,
                        minHeight: 200.0,
                        minWidth: 200.0,
                        highlightColor: 0xffffff,
                        midtoneColor: 0x0,
                        lowlightColor: 0x0,
                        baseColor: 0xc0c0c,
                        blurFactor: 0.1,
                        speed: 2.0,
                        zoom: 2.0,
                    });
                }
            } catch (error) {
                console.error('Error initializing Vanta.js:', error);
            }
        }
        return () => {
            if (vantaEffect.current) vantaEffect.current.destroy();
        };
    }, []);

    useEffect(() => {
        // Initialize typed text
        const options = {
            strings: ['Secure. Contain. Protect.'],
            typeSpeed: 100,
            backSpeed: 50,
            startDelay: 1000,
            backDelay: 2000,
            loop: true,
            showCursor: false,
            autoInsertCss: true,
        };
        const typed = new Typed('.typewriter', options);

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <>
            {/* SVG filter (distortion) */}
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ display: 'none' }}>
                <defs>
                    <filter id="distortion-filter">
                        <feTurbulence
                            type="fractalNoise"
                            baseFrequency="0.05"
                            numOctaves="4"
                            result="turbulence"
                        />
                        <feDisplacementMap
                            in="SourceGraphic"
                            in2="turbulence"
                            scale="30"
                        />
                        <feColorMatrix
                            type="hueRotate"
                            values="90"
                            result="colorShift"
                        />
                        <feComponentTransfer>
                            <feFuncR type="linear" slope="1.5" />
                            <feFuncG type="linear" slope="1.5" />
                            <feFuncB type="linear" slope="1.5" />
                        </feComponentTransfer>
                    </filter>
                </defs>
            </svg>

            <Box className="wrapper" ref={vantaRef}>
                <CssBaseline />
                <Box className="hero">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/scp-fm.appspot.com/o/logos%2Fscplogo.png?alt=media&token=9b1a5f42-7511-44ca-af95-527acc528a0c"
                        alt="SCP Logo"
                        height="500px"
                        loading="lazy"
                        className={`scp-logo ${imageLoaded ? 'distortion' : 'loading'}`}
                        onLoad={() => setImageLoaded(true)}
                    />
                    <Box className="typewriter-container">
                        <Typography variant="h4" className="typewriter"></Typography>
                    </Box>

                    <Typography variant="h1" className="title">
                        SCP: Facility Manager
                    </Typography>
                    
                    {/* divider */}
                    <Divider sx={{ 
                        borderColor: 'white',
                        margin: '20px auto',
                        width: '70%',
                        borderWidth: '1px',
                        opacity: 0.8
                    }} />

                    {/* Coming Soon + Discord Button */}
                    <Box textAlign="center" sx={{ 
                        marginTop: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '1.5rem'
                    }}>
                        <Typography 
                            variant="h4" 
                            sx={{
                                letterSpacing: '0.75rem',
                                fontWeight: 500,
                                opacity: 0.9
                            }}
                        >
                            COMING SOON
                        </Typography>
                        <Button
                            variant="contained"
                            size="large"
                            sx={{
                                padding: '12px 32px',
                                borderRadius: '8px',
                                fontSize: '1.1rem',
                                fontWeight: 500,
                                textTransform: 'none',
                            }}
                            href="https://discord.gg/kkgnV7mR"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            JOIN OUR COMMUNITY
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
