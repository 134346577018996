import React, { useState } from 'react';
import { Modal, Box, Input, Button, Alert, Typography, IconButton, FormHelperText } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';
import { useUser } from '../../context/UserContext';
import { fetchWithToken } from '../../utils/fetchUtils';

const ChangePassword: React.FC = () => {
  const { user } = useUser();
  const [open, setOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null);
  const [passwordError, setPasswordError] = useState('');

  const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError('Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character');
      return false;
    } else {
      setPasswordError('');
      return true;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validatePassword(newPassword)) {
      return;
    }

    if (newPassword !== confirmPassword) {
      setMessage({ type: 'error', text: 'New passwords do not match' });
      return;
    }

    try {
      const response = await fetchWithToken(`users/${user?.id}/change-password`, {
        method: 'POST',
        body: JSON.stringify({ currentPassword, newPassword }),
      });

      if (response.ok) {
        setMessage({ type: 'success', text: 'Password changed successfully!' });
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setTimeout(() => setOpen(false), 2000);
      } else {
        throw new Error(response.data?.message || 'Failed to change password');
      }
    } catch (error) {
      setMessage({ 
        type: 'error', 
        text: error instanceof Error ? error.message : 'Failed to change password'
      });
    }
  };

  return (
    <>
      <EditIcon
        onClick={() => setOpen(true)}
        sx={{
          cursor: 'pointer',
          ml: 1,
          fontSize: 'sm',
          '&:hover': { color: 'primary.main' },
        }}
      />

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box sx={{
          bgcolor: 'background.paper',
          p: 3,
          borderRadius: 'md',
          maxWidth: 400,
          width: '90%'
        }}>
          <Typography level="h4" sx={{ mb: 2 }}>Change Password</Typography>
          
          {message && (
            <Alert 
              color={message.type === 'success' ? 'success' : 'danger'}
              sx={{ mb: 2 }}
            >
              {message.text}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <Input
              type="password"
              required
              placeholder="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              sx={{ mb: 2, width: '100%' }}
            />

            <Input
              type="password"
              required
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onBlur={(e) => validatePassword(e.target.value)}
              error={!!passwordError}
              sx={{ mb: 1, width: '100%' }}
            />
            {passwordError && (
              <FormHelperText sx={{ color: 'danger.500', mb: 2 }}>
                {passwordError}
              </FormHelperText>
            )}

            <Input
              type="password"
              required
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{ mb: 2, width: '100%' }}
            />

            <Button type="submit" sx={{ width: '100%' }}>
              Change Password
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ChangePassword; 