import React, { useState } from 'react';
import { Modal, Box, Input, FormControl, FormLabel, Button, Alert, Typography, FormHelperText } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';
import { fetchWithToken } from '../../utils/fetchUtils';
import { useUser } from '../../context/UserContext';

const ChangeEmail: React.FC = () => {
  const { user } = useUser();
  const [open, setOpen] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [message, setMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null);
  const [emailError, setEmailError] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address');
      return false;
    } else {
      setEmailError('');
      return true;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateEmail(newEmail)) {
      return;
    }

    try {
      const response = await fetchWithToken(`users/${user?.id}/change-email`, {
        method: 'POST',
        body: JSON.stringify({ newEmail }),
      });

      if (response.ok) {
        setEmailSent(true);
        setMessage({ 
          type: 'success', 
          text: 'A verification link has been sent to your new email address. Please check your inbox to complete the change.' 
        });
        setNewEmail('');
        setTimeout(() => setOpen(false), 5000);
      } else {
        throw new Error(response.data.message || 'Failed to change email');
      }
    } catch (error) {
      setMessage({ 
        type: 'error', 
        text: error instanceof Error ? error.message : 'Failed to change email' 
      });
    }
  };

  return (
    <>
      <EditIcon
        onClick={() => setOpen(true)}
        sx={{
          cursor: 'pointer',
          ml: 1,
          fontSize: 'sm',
          '&:hover': { color: 'primary.main' },
        }}
      />

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            bgcolor: 'background.paper',
            p: 3,
            borderRadius: 'md',
            maxWidth: 400,
            width: '90%',
          }}
        >
          <Typography level="h4" sx={{ mb: 2 }}>
            Change Email Address
          </Typography>

          {message && (
            <Alert
              color={message.type === 'success' ? 'success' : 'danger'}
              sx={{ mb: 2 }}
            >
              {message.text}
            </Alert>
          )}

          <FormControl sx={{ mb: 2, width: '100%' }}>
            <FormLabel required>New Email Address</FormLabel>
            <Input
              placeholder="New Email Address"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              onBlur={(e) => validateEmail(e.target.value)}
              error={!!emailError}
              required
            />
            {emailError && (
              <FormHelperText sx={{ color: 'danger.500' }}>
                {emailError}
              </FormHelperText>
            )}
          </FormControl>

          <Button type="submit" sx={{ width: '100%' }}>
            Change Email
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ChangeEmail;
