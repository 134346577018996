import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/joy';
import { fetchWithToken } from '../utils/fetchUtils';
import { useUser } from '../context/UserContext';

export default function VerifyEmailChange() {
  const [verificationStatus, setVerificationStatus] = useState('Verifying email change...');
  const [isLoading, setIsLoading] = useState(true);
  const [verificationAttempted, setVerificationAttempted] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    const verifyEmailChange = async () => {
      if (verificationAttempted) return;
      
      try {
        setVerificationAttempted(true);
        const response = await fetchWithToken(`users/${user?.id}/change-email/verify?token=${token}`, {
          method: 'GET',
        });

        if (response.ok) {
          setVerificationStatus('Email changed successfully!');
          setTimeout(() => navigate('/profile'), 3000);
        } else {
          setVerificationStatus('Email change verification failed: ' + (response.data?.message || 'Unknown error'));
        }
      } catch (error) {
        setVerificationStatus('Email change verification failed');
      } finally {
        setIsLoading(false);
      }
    };

    if (token && user?.id && !verificationAttempted) {
      verifyEmailChange();
    }
  }, [location, navigate, user, verificationAttempted]);

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        p: 2
      }}
    >
      <Typography level="h2" sx={{ mb: 2 }}>
        Email Change Verification
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Typography level="body-lg">
          {verificationStatus}
        </Typography>
      )}
    </Box>
  );
} 